export const goods = "image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

/**
 * 设备类型
 */
export const deviceType = [
    {
        label: '保温箱',
        value: '保温箱'
    },
    {
        label: '冷藏车',
        value: '冷藏车'
    },
    {
        label: '其他',
        value: '其他'
    }
]



export const deviceStatus = ['正常', '在途', '已到达']