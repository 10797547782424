import { getQiniuToken } from "@/api/pdfToken.js";
import Axios from 'axios';

export class _sendUpFun {
    constructor() {
        this.token = null;
    }

    /**
     * 获取七牛云 token
     * @returns {Promise<string|null>} 返回 token 或 null
     */
    async getQiniuToken(e) {
        let file = e.raw;
        console.log(file);
        var strtype = file.name.substring(file.name.lastIndexOf('.') + 1); //获取后缀 png jpg
        const {data:res} = await getQiniuToken({ T_suffix: strtype});
        console.log(res);
        // try {
            
        //     console.log(res);
        //     if (res.Code === 200 && res.Msg === 'ok!') {
        //         this.token = res.Data;
        //         return this.token;
        //     } else {
        //         console.error('获取七牛云 Token 失败');
        //     }
        // } catch (error) {
        //     console.error('获取 token 发生错误:', error);
        // }
    }

    /**
     * 上传文件到七牛云
     * @param {File} file 要上传的文件
     * @returns {Promise<void>}
     */
    async uploadFile(file) {
        const token = await this.getQiniuToken(file);
        if (!token)return;
    }
}
