<template>
    <div class="Theorder">
        <div class="Theorder-T">
            <div class="Theorder-TL">
                <Breadcrumb></Breadcrumb>
                <div class="Theorder-searchs">
                    <div class="Theorder-searchs1">
                        <el-input v-model="initParam.t_orderid" clearable placeholder-style="color:red" style="width: 200px;" placeholder="订单号"></el-input>
                        <el-date-picker v-model="daterange" type="daterange" range-separator="至" 
                        value-format="yyyy-MM-dd"
                        start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                        <el-select v-model="initParam.t_sn" clearable placeholder="请选择设备编号">
                            <el-option v-for="item in options" :key="item.value" :label="item.T_devName" :value="item.T_sn">
                            </el-option>
                        </el-select>
                        <el-button type="primary" icon="el-icon-search" @click="_searchs()"></el-button>
                    </div>
                    <div class="Theorder-tit-mai1-btn">
                        <!-- @click="clickNew()" -->
                        <!-- <el-button type="danger" icon="el-icon-search">查询</el-button> -->
                    </div>
                </div>
            </div>
            <div class="Theorder-TR">
                <div class="Theorder-searchsMai">
                    <!-- <el-button type="danger" icon="el-icon-plus" @click="clickNew()">新增</el-button> -->
                </div>
            </div>
        </div>
        <div class="Theorder-B">
            <div class="Theorder2">
                <div style="margin-bottom: 20px; display: flex; gap:20px">
                    <el-button v-permission="'Order:Add'" @click="_clickNew('新增订单')" icon="el-icon-plus">新增订单</el-button>
                    <Address v-permission="'Addr:list'"/>
                    <courier v-permission="'Addr:list'"/>

                </div>
                <el-table :data="tableData" border style="width: 100%" size="small">
                    <template slot="empty">
                        <noData></noData>
                    </template>
                    	
                    <el-table-column prop="T_name" label="设备名称" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="DeviceType" label="设备类型" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="T_orderid" label="订单号码" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="WaybillNo" label="运单号码" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column label="是否外派">
                        <template slot-scope="scope" show-overflow-tooltip>
                            <el-tag :type="
                                scope.row.IsExpatriate==1?'':'warning'">{{scope.row.IsExpatriate==1?'否':'是'}}</el-tag>
                        </template>
                    </el-table-column>
                    
                    <el-table-column prop="T_receiving" label="收货单位" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="T_start_Ut" label="开始时间" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="T_end_Ut" label="抵达时间" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="CouriesName" label="配送员" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope" show-overflow-tooltip>
                            <el-tag :type="
                                scope.row.T_State==1?'':
                                scope.row.T_State==2?'warning':
                                'success'">{{deviceStatus[scope.row.T_State-1]}}</el-tag>
                        </template>
                    </el-table-column>
                    
                    <el-table-column prop="address" label="操作" fixed="right" width="220">
                        <template slot-scope="scope">
                            <div style="display:flex;gap:10px">
                                <preview :row="scope.row" v-permission="'Order:Get'"/>
                            <el-button type="primary" plain v-permission="'Order:Edit'" size="small"
                            @click="_clickNew('编辑订单',scope.row)" >编辑</el-button>
                            <el-button type="danger" style="margin-left:0" plain v-permission="'Order:Del'" size="small"
                                @click="_setdelApi(scope.row.Id)">删除</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="Theorder-pagination">
                <el-pagination
                :hide-on-single-page="true"
                @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                    :current-page="initParam.page" :page-size="initParam.page_z" layout="total, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>

        <el-dialog :title="titles"
        :close-on-click-modal="false" custom-class="y-dialog"
        @closed="_beforeclose"
        :visible.sync="dialogVisible" width="1000px" :append-to-body="true">
            <div class="NewUser" v-loading="loading">
                <el-form :model="ruleForm" :inline="true" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="订单号">
                        <el-input v-model="ruleForm.T_orderid" disabled placeholder="订单号" class="conter_width"></el-input>
                    </el-form-item>
                    <el-form-item label="运单号">
                        <el-input v-model="ruleForm.WaybillNo" placeholder="出库订单号" class="conter_width"></el-input>
                    </el-form-item>
                    <el-form-item label="设备编号" prop="T_sn">
                        <el-select v-model="ruleForm.T_sn" placeholder="设备编号" class="conter_width">
                            <el-option v-for="item in options" :key="item.value" :label="item.T_devName" :value="item.T_sn">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="设备类型" prop="DeviceType">
                        <el-select v-model="ruleForm.DeviceType" placeholder="设备类型" class="conter_width">
                            <el-option v-for="item in deviceType" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否外派" prop="IsExpatriate">
                        <el-radio-group v-model="ruleForm.IsExpatriate" @change="_inputGroup" class="conter_width">
							<el-radio :label="1">否</el-radio>
							<el-radio :label="2">是</el-radio> <!-- disabled -->
						</el-radio-group>
                    </el-form-item>
                    <el-form-item label="手机号码" prop="Phone" v-if="ruleForm.IsExpatriate==2">
                        <el-input v-model="ruleForm.Phone" maxlength="11" placeholder="手机号码" class="conter_width"></el-input>
                    </el-form-item>
                    <el-form-item label="收货单位" prop="ChildPid">
                        <el-cascader v-model="ruleForm.ChildPid" :options="optionsTree"
                        filterable :show-all-levels="false" class="conter_width"
                        @change="handleChange"
                        :props="{ children: 'Children', checkStrictly: true, label: 'T_name', value: 'Id', emitPath: false,}"
                        ></el-cascader>
                    </el-form-item>

                    
                    <el-form-item label="发货单位" prop="ShippingUnit">
                        <el-input v-model="ruleForm.ShippingUnit" placeholder="发货单位" class="conter_width"></el-input>
                    </el-form-item>
                    <el-form-item label="配送计划" prop="T_time">
                        <el-date-picker v-model="ruleForm.T_time" type="daterange" class="conter_width"
                            value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="配送员" prop="ShippingUnit">
                        <el-select v-model="ruleForm.CouriesId" placeholder="请选择" class="conter_width">
                            <el-option
                            v-for="item in optionsCouries"
                            :key="item.Id"
                            :label="item.T_name"
                            :value="item.Id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="随货同行单">
                        <el-upload
                         class="conter_width"
                            ref="uploadRef"
                            action=""
                            :multiple="true"
                            :limit="5" 
                            :auto-upload="false"
                            :on-change="changeFile"
                            :before-remove="removeFile"
                            accept="image/*,application/pdf,application/octet-stream,application/zip,application/x-tar,application/msword"
                            >
                            <el-button size="small" @click="_onclickupload" icon="el-icon-link">上传附件</el-button>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="详情">
                        <el-input type="textarea" v-model="ruleForm.T_text" class="conter_width"></el-input>
                    </el-form-item>
                </el-form>
                <el-divider></el-divider>
                <div class="_footer">
                    <el-button type="primary" :disabled="disabled" @click="submitForm('ruleForm')">立即提交</el-button>
                    <el-button @click="dialogVisible = false">取消</el-button>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import {
    OrderList,
    OrderAdd,
    DeviceList,
    getNumber,
    getCompany,
    getcompanyTree,
    OrderDel,OrderEdit,AddressList,testApi,CouriersList
} from '@/api/instrument/Theorder.js'

import { getQiniuToken } from "@/api/pdfToken.js";
import { _sendUpFun } from './js/qiliuyun.js';
import {rules} from "./js/rules.js";
import {initParam,ruleForm,initForm} from './js/initParam.js'
import { deviceType,deviceStatus } from "./js/goods.js";
import * as qiniu from 'qiniu-js';
import Address from './address.vue'
import preview from './preview.vue'
import courier from './courier.vue'

const QINIU_DOMAIN = 'https://qiniu.region.z2'; 

export default {
    components: {
        Address,preview,courier
    },
    data() {
        return {
            uploadData: {},
            uploadInstance: new _sendUpFun(),
            initParam,//列表请求参数
            deviceType,//设备类型下拉数据
            deviceStatus,//设备状态下拉数据
            ruleForm,//新增编辑请求参数
            daterange: '',
            total: 0,
            rules,
            titles: '',
            loading: false,
            optionsTree: [],//公司下拉数据
            disabled:false,//禁止新增编辑的提交按钮
            options: [],
            dialogVisible: false,
            tableData: [],
            optionsCouries:[]//配送员列表
            
        }
    },
    mounted() {
        this._getTheorderApi()
        this._getDeviceListApi()
    },
    methods: {
        removeFile(e){
            console.log('删除',e,this.ruleForm.imgArr)
            const index = this.ruleForm.imgArr.findIndex(item=>item.name==e.name)
            if (index !== -1) {
                this.ruleForm.imgArr.splice(index, 1);
            }
        },
        
        _inputGroup(e){
            console.log('切换',e)
            this.ruleForm.ChildPid = ''//清空收货单位
            this.ruleForm.Phone = ''//清空手机号码
            if(e==1){
                this._getTree()
            }else{
                this._getAddressListApi()
            }
            
        },
        /**
         * 地址管理列表
         */
        _getAddressListApi() {
            AddressList({page: 1,page_z:9999}).then(res => {
                let {data:result} = res
                if (result.Code == 200) {
                    this.optionsTree = result.Data.Data || []
                    console.log(this.optionsTree)
                }
            }) 
        },
        async _getTree(){
            const {data:res} = await getcompanyTree({page_z: 999999})//获取公司树
            this.optionsTree = this.removeEmptyChildren(res.Data.Data)
        },
        _onclickupload() {
            this.$refs.uploadRef.clearFiles();
        },
        async changeFile(e) {
            this.loading = true;
            const files = Array.isArray(e) ? e : [e.raw];
            for (const file of files) {
                var strtype = file.name.substring(file.name.lastIndexOf('.') + 1);
                const { data: res } = await getQiniuToken({ T_suffix: strtype });
                if (res.Code === 200 && res.Msg === 'ok!') {
                    await this._qiniuYun(file, res.Data);
                }
            }
            console.log('上传结束',files)
            this.loading = false;
        },
        _qiniuYun(file, token) {
            var _this = this;
            var config = {
                useCdnDomain: false, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
                region: qiniu.region.z2,
                domain: QINIU_DOMAIN, //配置好的七牛云域名  如   https://cdn.qniyun.com/
                chunkSize: 1000, //每个分片的大小，单位mb，默认值3
                forceDirect: false //直传还是断点续传方式，true为直传
            };
            var putExtra = {
                fname: file.name,
                params: {},
                mimeType: ['image/*', 'application/pdf', 'application/octet-stream', 'application/zip', 'application/x-tar', 'application/msword'] || null
            };
            var observable = qiniu.upload(file, file.name, token, putExtra, config);
            var observer = {
                next(res) {
                    if (parseInt(res.total.percent) === 100) {
                        _this.loading = false;
                    }
                },
                error(err) {
                    _this.loading = false;
                    switch (err.code) {
                        case 413:
                            _this.$message.error('错了哦，图片可能太大了哦');
                            break;
                        case 408:
                            _this.$message.error('错了哦，请求超时');
                            break;
                        default:
                            _this.$message.error('错了哦，上传错误');
                    }
                },
                complete(com) {
                    console.log('文件上传成功', com,file);
                    _this.ruleForm.imgArr.push({name:file.name,key:com.key});
                }
            };
            return new Promise((resolve, reject) => {
                observable.subscribe({
                    ...observer,
                    complete: (com) => {
                        observer.complete(com);
                        resolve();
                    },
                    error: (err) => {
                        observer.error(err);
                        reject(err);
                    }
                });
            });
        },
        /**
         * 列表数据
         */
        _getTheorderApi() {
            if(this.daterange.length>0){
                this.initParam.startTime = this.daterange[0]
                this.initParam.endTime = this.daterange[1]
            }
            OrderList(this.initParam).then(res => {
                if (res.data.Code === 200 && res.data.Msg === 'ok!') {
                    this.total = res.data.Data.Num || 0
                    this.tableData = res.data.Data.Data || []
                }
            })
        },

        _searchs(){
            this.initParam.page = 1
            this._getTheorderApi()
        },
        /**
         * 清空上传随货同行单文件
         */
        _beforeclose(){
            if (this.$refs.uploadRef.uploadFiles.length>0) {
                this.$refs.uploadRef.uploadFiles = []
            }
        },
        /**
         * 新增、编辑订单
         */
        async _clickNew(title,item=null) {
            this.titles = title
            this.disabled = false
            
            this.dialogVisible = true
            
            if (this.$refs.ruleForm) {//为了验证第一次触发表单验证，第二次打开还是触发
                this.$refs.ruleForm.resetFields();
            }
            Object.keys(this.ruleForm).forEach(key => {
                if (key == 'IsExpatriate'){this.ruleForm[key] = 1}else{this.ruleForm[key] = ''}
            })
            CouriersList({page_z:9999}).then(res => {
                this.optionsCouries = res.data.Data.Data
            })
            if (title == '新增订单') {
                const {data:result} = await getNumber({}) 
                this.ruleForm.T_orderid = result.Data
                const {data:result1} = await getCompany({})//获取公司名称
                this.ruleForm.ShippingUnit = result1.Data.Data.T_name
                this.ruleForm.imgArr = []
                this._getTree()
            }else{//编辑订单
                if(item.IsExpatriate==1){
                    this._getTree()
                }else{
                    this._getAddressListApi()
                }
                Object.keys(this.ruleForm).forEach(key => {
                    this.ruleForm[key] = item[key]
                })
                this.ruleForm.T_time = [this.ruleForm.EstimateStartTime.split(' ')[0],this.ruleForm.EstimateEndTime.split(' ')[0]]
                this.ruleForm.ChildPid = Number(this.ruleForm.ChildPid)
                this.ruleForm.CouriesId = Number(this.ruleForm.CouriesId)

                this.ruleForm.T_id = item.Id

                this.ruleForm.imgArr = []
            }
        },
        /**
         * 去除空Children
         */
        removeEmptyChildren(arr) {
            for (let i = 0; i < arr.length; i++) {
                const item = arr[i];
                if (item.Children && item.Children.length === 0) {
                    delete item.Children;
                } else if (item.Children) {
                    this.removeEmptyChildren(item.Children);
                }
            }
            return arr;
        },
        /**
         * 提交新增、编辑
         * @param formName 表单名
         */
        submitForm(formName) {
            var self = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    self.disabled = true
                    var obj = { ...self.ruleForm }
                    obj.EstimateStartTime = obj.T_time[0]
                    obj.EstimateEndTime = obj.T_time[1]
                    delete obj.T_time
                    if (obj.IsExpatriate == 1) obj.Phone = ''
                    obj.PeerList = obj.imgArr.map(item => item.key).join(',');
                    if (self.titles == '新增订单') {
                        self._setAdd(obj)
                    } else {//编辑订单
                        self._setEdit(obj)
                    }
                }
            });
        },
        /**
         * 新增Api
         */
        _setAdd(obj) {
            var self = this
            OrderAdd(obj).then(res => {
                console.log('添加',res)
                if (res.data.Code === 200 && res.data.Msg === "ok!") {
                    self.initParam.page = 1
                    self._getTheorderApi()
                    self.$message.success('新增成功！')
                    self.dialogVisible = false
                }
                self.disabled = false
            })
        },
        /**
         * 编辑api
         * @param e 
         */
        _setEdit(e) {
            var self = this
            OrderEdit(e).then(res => {
                if (res.data.Code === 200 && res.data.Msg === "ok!") {
                    self.initParam.page = 1
                    self._getTheorderApi()
                    self.$message.success('编辑成功！')
                    self.dialogVisible = false
                }
            })
        },
        /**
         * 删除api
         * @param id 
         */
        _setdelApi(id) {
            let _this = this
            this.$confirm('删除操作，是否继续？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                console.log('删除',id)
                OrderDel({ T_id: id }).then(res => {
                    console.log('删除成功',res)
                    if (res.data.Code === 200 && res.data.Msg == "ok!") {
                        _this.initParam.page = 1
                        _this._getTheorderApi()
                        _this.$message.success('删除成功')
                    }
                })
            }).catch(() => {});
        },
        /**
         * 筛选出选中收货单位id对应的公司名称
         * @param value 
         */
        handleChange(value) {
            this.ruleForm.T_receiving = this._findTNameById(value, this.optionsTree);
            if(this.ruleForm.IsExpatriate==2){
                const result = this.optionsTree.find(item=>item.Id==value)
                this.ruleForm.Phone = result.Phone
            }
        },
        /**
         * 根据公司id筛选出对应的公司名称
         * @param id 公司id
         * @param arr 公司数组
         */
        _findTNameById(id, arr) {
            for (let i = 0; i < arr.length; i++) {
                const currentObj = arr[i];
                if (currentObj.Id === id) {
                    return currentObj.T_name;
                }
                if (currentObj.Children && currentObj.Children.length > 0) {
                    const result = this._findTNameById(id, currentObj.Children);
                    if (result) {
                        return result;
                    }
                }
            }
            return null;
        },
        /**
         * 每页多少条
         */
        handleSizeChange(e) { 
            this.initParam.page_z = e
            this._getTheorderApi()
        },
        /**
         * 跳转页
         */
        handleCurrentChange(e) {
            this.initParam.page = e
            this._getTheorderApi()
        },
        /**
         * 设备编号select框
         */
         _getDeviceListApi() {
            var that = this
            DeviceList({
                page: 1,
                page_z: 9999999
            }).then(res => {
                if (res.data.Code === 200 && res.data.Msg === "ok!") {
                    that.options = res.data.Data.Device_lite
                }
            })
        },
    },
    filters: {
        Time: function (time) { //时间戳转换
            if (time) {
                var date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
                let y = date.getFullYear();
                let MM = (date.getMonth() + 1).toString().padStart(2, '0');
                let d = date.getDate().toString().padStart(2, '0');
                let h = date.getHours().toString().padStart(2, '0');
                let m = date.getMinutes().toString().padStart(2, '0');
                let s = date.getSeconds().toString().padStart(2, '0');
                return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
            } else {
                return '0000-00-00  00-00-00'
            }
        },
    },
}
</script>

<style lang="scss">
.conter_width{
    width:350px;
}
._footer{
    display: flex;
    justify-content: center;
}
.Theorder {
    .Theorder-T {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(#fff, 1);
        height: 70px;
        padding: 15px 10px;
        box-shadow: 5px 5px 10px -10px #000;
        .Theorder-TL {
            display: flex;
            align-items: center;

            .Theorder-searchs {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 20px;


                .Theorder-searchs1 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                }
            }
        }

        .Theorder-searchsMai {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    .Theorder-B {
        background: rgba(#fff, 1);
    }

    .Theorder2 {
        overflow: hidden;
        margin: 10px;
        padding: 20px;
    }

    .Theorder-pagination {
        padding: 50px 20px;
        margin: 10px;
        border-radius: 2px;
        display: flex;
    }
}
</style>
